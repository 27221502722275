// Import Flatpickr and language
import Flatpickr from "stimulus-flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"

export default class extends Flatpickr {

  initialize() {

    // Get picker properties
    const enableTime = this.element.getAttribute("data-flatpickr-enable-time")
    const noCalendar = this.element.getAttribute("data-flatpickr-no-calendar")

    // Choose the right date format
    let dateFormat = "d.m.Y"
    if (enableTime != null && noCalendar != null)
      dateFormat = "H:i"
    else if (enableTime != null)
      dateFormat = "d.m.Y H:i"

    // Set config
    this.config = {
      locale: German,
      allowInput: true,
      dateFormat: dateFormat
    }

    // Set allowInput: true, and forbid keypress is a hack to allow HTML validations
    // https://github.com/flatpickr/flatpickr/issues/892
    this.element.onkeypress = () => false
    this.element.setAttribute("autocomplete", "off")
  }
}
