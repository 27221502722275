import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "street", "postalcode", "location", "country", "organizationName",
    "schoolName", "customerSubtype", "vatId"
  ]

  connect() {
    setTimeout(function() {
      document.getElementById("user_customer_type").
        dispatchEvent(new Event("change"))
    }, 1)
  }

  changedCustomerType(event) {

    // Get select/input tag from targets
    const organizationField = this.organizationNameTarget.
      querySelector("input")
    const schoolField = this.schoolNameTarget.
      querySelector("select")
    const customerSubtypeField = this.customerSubtypeTarget.
      querySelector("select")

    // Hide all fields
    this.organizationNameTarget.classList.add("d-none")
    this.schoolNameTarget.classList.add("d-none")
    this.customerSubtypeTarget.classList.add("d-none")
    if (this.hasVatIdTarget)
      this.vatIdTarget.classList.add("d-none")

    // Remove required attributes
    customerSubtypeField.removeAttribute("required")
    schoolField.removeAttribute("required")

    // Remove name attribute
    organizationField.removeAttribute("name")
    schoolField.removeAttribute("name")

    this.enableCountrySelector()

    // If customer-type is austrian school, display school select
    if (event.target.value == "austrian_school") {
      schoolField.setAttribute("required", "required")
      this.disableCountrySelector()
      this.schoolNameTarget.classList.remove("d-none")
      schoolField.setAttribute("name", "user[organization_name]")
      if (this.hasVatIdTarget)
        this.vatIdTarget.classList.remove("d-none")

    // If customer-type is other education provider, display subtype
    } else if (event.target.value == "other_education_provider") {
      this.disableCountrySelector()
      this.organizationNameTarget.classList.remove("d-none")
      organizationField.setAttribute("name", "user[organization_name]")
      customerSubtypeField.setAttribute("required", "required")
      this.customerSubtypeTarget.classList.remove("d-none")
      if (this.hasVatIdTarget)
        this.vatIdTarget.classList.remove("d-none")

    // Else if customer is not private display organization name field
    } else if (event.target.value != "private") {
      this.organizationNameTarget.classList.remove("d-none")
      organizationField.setAttribute("name", "user[organization_name]")
      if (this.hasVatIdTarget)
        this.vatIdTarget.classList.remove("d-none")
    }
  }

  enableCountrySelector() {
    const selectController = this.application.getControllerForElementAndIdentifier(
      this.countryTarget, "select"
    )
    selectController.clearValue()
    selectController.enableChoices()
  }

  disableCountrySelector() {
    const selectController = this.application.getControllerForElementAndIdentifier(
      this.countryTarget, "select"
    )
    selectController.setValue("AT")
    selectController.disableChoices()
  }

  selectedSchool(customProperties) {
    this.streetTarget.value = customProperties.street
    this.postalcodeTarget.value = customProperties.postalcode
    this.locationTarget.value = customProperties.location
    this.countryTarget.value = customProperties.country
    this.countryTarget.dispatchEvent(new Event("change"))
  }
}
