import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  connect() {

    // Create controller variable to track whether form changes happened
    const controller = this
    controller.formHasChanged = false

    // Track all input, file and trix editor changes
    this.element.addEventListener("input", () => {
      controller.formHasChanged = true
    })

    // Track all select and checkbox changes
    this.element.addEventListener("change", () => {
      controller.formHasChanged = true
    })

    // Register event handler for saving
    document.querySelector("#unsavedChangesModal .save").addEventListener(
      "click", () => {

        // Save but stay on this site. This is done by changing the form action
        // attribute. Controller save action has to support redirect_to
        // parameter.
        let url = new URL(
          window.location.origin + controller.element.getAttribute("action")
        )
        url.searchParams.set("redirect_to", document.location.href)
        controller.element.setAttribute("action", url.toString())

        // Submit form
        controller.element.submit()
      }
    )
  }

  disconnect() {

    // Should prevent flickering for turbo-cache
    // TODO: Investigate why this doesn't work?
    // Would also work if we would hide modal in CSS while cache is diplayed
    new Modal(document.getElementById('unsavedChangesModal')).hide()
  }

  saveChangesModal(event) {

    // If changes happened, display save/discard modal
    if (this.formHasChanged) {
      event.preventDefault()
      document.querySelector("#unsavedChangesModal .ignore").setAttribute(
        "href", event.target.getAttribute("href")
      )

      new Modal(document.getElementById('unsavedChangesModal')).show()
    }
  }
}
