import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "dateRangePicker", "commissionRecipientSelect"
  ]

  // When date or user is changed, reload page with new filter params
  changedFilters(event) {

    // Generate new url, using month, day and user filters
    let url = new URL(window.location)
    const dateRange = this.dateRangePickerTarget.value.split(" bis ")

    const controller = this
    setTimeout(function() {

      // Only proceed if a from-to date is selected
      // To detect this correctly, we wrap this into a timeout
      // Clicking on the user link should not depend on a selected range
      const calendar = controller.dateRangePickerTarget._flatpickr.
        calendarContainer
      if (
        !(
          event.target.closest("a") &&
          event.target.closest("a").classList.
            contains("commission-recipient-select-link")
        ) &&
        !(calendar && calendar.querySelector(".endRange"))
      ) return

      // Set URL params
      url.searchParams.set("from", dateRange[0])
      url.searchParams.set("to", dateRange[1] || dateRange[0])

      // If commission-recipient-switch was triggered, use the current target
      if (
        event.target.closest("a") && event.target.closest("a").classList.
          contains("commission-recipient-select-link")
      ) {
        url.searchParams.set("id",
          event.target.closest("a").getAttribute("data-id")
        )

      // Else if date were switched, use already selected user
      } else {
        url.searchParams.set("id",
          controller.commissionRecipientSelectTargets.filter(
            link => link.classList.contains("selected")
          )[0].getAttribute("data-id")
        )
      }

      // Reload page with new parameters
      Turbo.visit(url.toString(), { action: "replace" })
    }, 1)
  }
}
