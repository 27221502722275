import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "date", "reservableSelect" ]

  navigateCalendarsToPrev(event) {
    document.querySelectorAll('[data-controller="calendar"]').forEach((element) => {
      const calendar_controller = this.application.getControllerForElementAndIdentifier(
        element, "calendar"
      )
      calendar_controller.navigateToPrev(event)
    })
    event.preventDefault()
  }

  navigateCalendarsToNext(event) {
    document.querySelectorAll('[data-controller="calendar"]').forEach((element) => {
      const calendar_controller = this.application.getControllerForElementAndIdentifier(
        element, "calendar"
      )
      calendar_controller.navigateToNext(event)
    })
    event.preventDefault()
  }

  navigateCalendarsToToday(event) {
    document.querySelectorAll('[data-controller="calendar"]').forEach((element) => {
      const calendar_controller = this.application.getControllerForElementAndIdentifier(
        element, "calendar"
      )
      calendar_controller.navigateToToday(event)
    })
    event.preventDefault()
  }

  // Sync date selector with calenndar
  changedDate(event) {

    // Get calendar controller instance
    const calendar_controller = this.application.getControllerForElementAndIdentifier(
      this.element.getElementsByClassName("form-calendar")[0], "calendar"
    )

    // Update calendar day
    const parts = this.dateTarget.value.match(/(\d+)/g)
    const date = new Date(parts[2], parts[1]-1, parts[0])
    calendar_controller.calendar.setDate(date)

    // Construct params string (fetch via GET only allow URL params)
    let params = new URLSearchParams(new FormData(this.element)).toString()
    params += "&reservable_id=" + this.reservableSelectTarget.selectedOptions[0].value

    // Ajax request to get all reserved state
    const controller = this
    fetch(this.data.get("selectUrl") + "?" + params, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.json()).then(function(response) {
      controller.reservableSelectTarget.outerHTML = response.select
    })
  }

  // Sync reservable selector with calendar
  changedReservable(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Get calendar controller instance
    const calendar_controller = this.application.getControllerForElementAndIdentifier(
      this.element.getElementsByClassName("form-calendar")[0], "calendar"
    )

    // Update calendar schedule
    const schedules = calendar_controller.generateSchedules(response)
    calendar_controller.calendar.clear()
    calendar_controller.calendar.createSchedules(schedules)
    calendar_controller.calendar.render()
  }
}
