import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "participantCounter", "fullPriceTickets", "reducedPriceTickets",
    "freeTickets", "newResourceForm", "newResource", "removeResource"
  ]

  // Triggered when checked-in a guide
  checkedIn(event) {
    event.target.querySelectorAll("span").forEach((target) => {
      target.classList.toggle("d-none")
    })
  }

  removeResource(event) {
    event.preventDefault()
    event.target.parentNode.parentNode.remove()
  }

  newResource(event) {
    event.preventDefault()

    const form = event.target.parentNode.parentNode.parentNode.
      querySelector(".new-resource-form").querySelector(".row").
      cloneNode(true)
    event.target.parentNode.parentNode.parentNode.
      querySelector('.existing-resources').append(form)
  }

  // Triggers whenever the number of participants has changed
  // Also disables form submit button if it is not allowed
  // Used e.g. in booking-view and shop
  changedParticipants(event) {

    // Sum up participants
    let sum =
      (parseInt(this.fullPriceTicketsTarget.value) || 0) +
      (parseInt(this.reducedPriceTicketsTarget.value) || 0)

    if (this.hasFreeTicketsTarget)
      sum += parseInt(this.freeTicketsTarget.value) || 0

    // Update text
    this.participantCounterTarget.innerText = sum

    // Validate
    const error = (
      (sum > parseInt(this.data.get("max-participants"))) ||
      (sum < parseInt(this.data.get("min-participants"))) ||
      (sum == 0)
    )

    // Toggle error class
    this.participantCounterTarget.parentNode.classList.toggle(
      "text-danger", error
    )
    this.participantCounterTarget.parentNode.classList.toggle(
      "error", error
    )

    // Get form and button elements
    const form = this.participantCounterTarget.closest("form")
    const submitBtn = form.querySelector("input[type=submit]")

    // Enable or disable submit button
    submitBtn.toggleAttribute(
      "disabled", form.querySelector(".error") != null
    )
  }
}
