import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "picker", "table", "timeEntries", "timeEntry" ]

  connect() {

    // Init with inital dates
    this.dates = []
    this.timeEntryTargets.forEach((target) => {
      this.dates.push(target.getAttribute("data-date"))
    })

    // Wait for flatpickr to be initialized
    const controller = this
    setTimeout(function() {

      // Return if no picker target exists (e.g. for seasonal openings)
      if (!controller.hasPickerTarget)
        return

      // Get picker target
      const picker = controller.pickerTarget._flatpickr

      // Use flatpickr onChange event as best effort to get clicked day
      picker.set("onChange", function(selectedDates, dateStr, instance) {

        // Format date strings
        selectedDates = selectedDates.map((date) => {
          return controller.formatDateToString(date)
        })

        // Check which date was added or removed by intersecting the new and old
        const added_dates = selectedDates.filter(
          x => !controller.dates.includes(x)
        )
        const removed_dates = controller.dates.filter(
          x => !selectedDates.includes(x)
        )

        // Depending on whether a date was added or removed, choose action
        if (added_dates.length)
          controller.addTimeEntry(added_dates[0])
        else if (removed_dates.length)
          controller.removeTimeEntry(removed_dates[0])

        // Update old_dates by value
        controller.dates = [...selectedDates]
      })
    }, 1)
  }

  addTimeEntry(added_date) {
    const controller = this
    const is_first_param = this.data.get("mockTimeEntryUrl").indexOf("?") == -1
    const char = is_first_param ? "?" : "&"

    fetch(this.data.get("mockTimeEntryUrl") + char + "date=" + added_date).
    then(response => response.json()).then(function(response) {

      let inserted = false

      // If no dates, just insert
      if (!controller.timeEntryTargets.length) {
        controller.timeEntriesTarget.innerHTML = response.time_entry
        inserted = true
      }

      // Try to insert it where needed
      // String have to be converted to dates to compare them
      controller.timeEntryTargets.forEach((target) => {
        let targetDateTmp = controller.formatStringToDate(
          target.getAttribute("data-date")
        )
        let addedDateTmp = controller.formatStringToDate(
          added_date
        )
        if (targetDateTmp > addedDateTmp && !inserted){
          target.insertAdjacentHTML("beforebegin", response.time_entry)
          inserted = true
        }
      })

      // If not in between, insert it at the end
      if (!inserted)
        controller.timeEntriesTarget.insertAdjacentHTML(
          "beforeend", response.time_entry
        )
    })
  }

  removeTimeEntry(removed_date) {
    this.timeEntryTargets.forEach((target) => {
      if (target.getAttribute("data-date") == removed_date)
        target.remove()
    })
  }

  // Converts a JS date to a simple DD.MM.YYYY string
  formatDateToString(date) {
    let
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join(".")
  }

  // Converts a JS date to a simple DD.MM.YYYY format
  formatStringToDate(string) {
    let
      day = string.split(".")[0],
      month = string.split(".")[1],
      year = string.split(".")[2]

    return Date.parse([year, month, day].join("."))
  }

  // Toggles whole-day / time-range
  toggleWholeDay(event) {
    const container = event.target.closest(".time-entry").querySelector(
      "[data-whole-day]"
    )
    const state = container.getAttribute("data-whole-day")
    container.setAttribute("data-whole-day", state == "false")
  }

  // Deselects an entry
  deselectTimeEntry(event) {
    const date = event.target.closest(".time-entry").getAttribute("data-date")
    const sameDateEntries = this.timeEntriesTarget.querySelectorAll(
      ".time-entry[data-date='" + date + "']"
    )

    // If there are multiple time entries for a date, just remove clicked one
    if (sameDateEntries.length > 1) {
      event.target.closest(".time-entry").remove()

    // If there is only one time entry for a date, remove from picker
    } else {
      const dates = this.dates.filter(x => x != date)
      this.pickerTarget._flatpickr.setDate(dates, true)
    }
  }

  // Copies an entry
  duplicateTimeEntry(event) {
    const entry = event.target.closest(".time-entry")
    entry.insertAdjacentHTML("afterend", entry.outerHTML)
    const newEntry = entry.nextSibling

    // Remove id input
    newEntry.querySelector("input[name='time_entries[][id]'").value = ""

    // This fixes dupliate flatpickrs, can be done better?
    newEntry.querySelectorAll(".time-input").forEach((target) => {
      target.querySelector(".flatpickr-calendar:last-child").remove()
    })
  }
}
