import { Controller } from "@hotwired/stimulus"
import Calendar from "tui-calendar"

export default class extends Controller {

  static targets = [ "navigation", "calendar" ]

  connect() {
    this.initCalendar()
    this.fetchCalendarData()

    // Set start date if date attribute is given
    if (this.data.get("date"))
    this.calendar.setDate(new Date(this.data.get("date")))
  }

  initCalendar() {
    let theme = {}

    if (this.data.get("style") == "mini") {
      theme = {
        "week.timegridOneHour.height": "16px",
        "week.timegridHalfHour.height": "8px"
      }
    }

    // Empty loading state
    this.calendarTarget.innerHTML = ""

    // Init calendar
    // Use custom template to allow html titles
    this.calendar = new Calendar(this.calendarTarget, {
      scheduleId: 1,
      calendarId: 1,
      defaultView: this.data.get("view") || "week",
      scheduleView: ["time"],
      taskView: false,
      isReadOnly: true,
      useDetailPopup: true,
      template: {
        time: function(schedule) {
          return schedule.body
        },
        weekDayname: function(dayname) {
          const date = dayname.renderDate.split("-")
          return (
            "<span class=\"calendar-week-dayname-name\">" +
              dayname.dayName.substring(0, 2) +
            "</span><span class=\"calendar-dayname-divider\">,"+
            "</span><span class=\"calendar-week-dayname-date\">" +
              date[2] + "." + date[1] + "." + date[0] +
            "</span>"
          )
        },
        timegridDisplayPrimayTime: function(time) {
          return (
            ("0" + time.hour).slice(-2) + ":" + ("0" + time.minutes).slice(-2)
          )
        }
      },
      theme: theme,
      week: {
        daynames: [
          "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag",
          "Freitag", "Samstag"
        ],
        startDayOfWeek: 1
      },
    })
  }

  fetchCalendarData() {
    const from = new Date(
      this.calendar.getDateRangeStart().toDate()
    ).toISOString()
    const to = new Date(
      this.calendar.getDateRangeEnd().toDate()
    ).toISOString()

    let url = new URL(this.data.get("contentUrl"), "http://example.com")

    url.searchParams.append("from", from)
    url.searchParams.append("to", to)

    // Fetch calendar data from backend
    fetch(url.pathname + url.search, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      if (response.redirected && response.url.match('sign_in')) {
        location.reload()
      }
      else {
        return response.json()
      }
    })
    .then(response => { this.processCalendarResponse(response) })
  }

  processCalendarResponse(response) {
    this.calendar.clear()

    // Populate calendar with schedules
    const schedules = this.generateSchedules(response)
    this.calendar.createSchedules(schedules)
  }

  disconnect() {
    this.calendar.destroy()
  }

  // Generates schedule entries for calendar
  generateSchedules(response) {
    let schedules = []
    response.forEach((entry) => {
      schedules.push({
        body: entry.body,
        start: new Date(entry.start_at),
        end: new Date(entry.end_at),
        category: "time",
        bgColor: entry.color,
        color: "#ffffff"
      })
    })
    return schedules
  }

  switchToDayView(event) {
    this.calendar.changeView("day", false)
    this.navigationTarget.classList.remove("week")
    this.calendarTarget.classList.remove("week")
    this.navigationTarget.classList.add("day")
    this.calendarTarget.classList.add("day")
    event.preventDefault()
  }

  switchToWeekView(event) {
    this.calendar.changeView("week", false)
    this.navigationTarget.classList.remove("day")
    this.calendarTarget.classList.remove("day")
    this.navigationTarget.classList.add("week")
    this.calendarTarget.classList.add("week")
    this.fetchCalendarData()
    event.preventDefault()
  }

  navigateToPrev(event) {
    this.calendar.prev()
    this.fetchCalendarData()
    event.preventDefault()
  }

  navigateToNext(event) {
    this.calendar.next()
    this.fetchCalendarData()
    event.preventDefault()
  }

  navigateToToday(event) {
    this.calendar.today()
    this.fetchCalendarData()
    event.preventDefault()
  }
}
