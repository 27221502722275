import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "inland", "form", "hideSchoolSwitch", "showSchoolSwitch", "schoolFilters"
  ]

  hideSchoolFilters(event) {
    this.inlandTarget.checked = false
    this.schoolFiltersTarget.classList.add("d-none")
    this.showSchoolSwitchTarget.classList.remove("school--selected")
    event.target.classList.add("school--selected")
  }

  showSchoolFilters(event) {
    this.inlandTarget.checked = true
    this.schoolFiltersTarget.classList.remove("d-none")
    this.hideSchoolSwitchTarget.classList.remove("school--selected")
    event.target.classList.add("school--selected")
  }
}
