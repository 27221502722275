import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "offerType",
    "availabilityDays", "availabilitySeasonalOpening", "availabilityWrapper",
    "availabilitySelector", "availabilitySeasons",
    "availabilitySeasonalOpeningElement",
    "location",
    "fixedPrice", "fullPrice",
    "fixedGroupPrice", "reducedFixedGroupPrice",
    "reducedPrice", "minParticipants", "guideSkills"
  ]

  connect() {

    // Save seasonal opening options template and filter if location is set.
    this.allSeasonalOpeningOptions = this.
      availabilitySeasonalOpeningElementTarget.
      cloneNode(true)
    if (this.locationTarget.value) this.filterSeasonalOpeningOptions(
      this.locationTarget.value, false
    )

    this.categoryChanged()
  }

  // Remove irrelevant seasonal opening options.
  filterSeasonalOpeningOptions(location, removeSelected = true) {
    const openingSelect = this.availabilitySeasonalOpeningElementTarget
    let newSelect = this.availabilitySeasonalOpeningElementTarget.cloneNode(true)
    openingSelect.replaceChildren()

    for (let element of newSelect.children) {
      if (removeSelected && element.selected) { element.selected = false }
      if (!element.value || (element.dataset && element.dataset.location &&
        element.dataset.location == location)) {
        openingSelect.appendChild(element.cloneNode(true))
      }
    }
  }

  // Change seasonal opening options for alwys until / always from field
  // according to location.
  locationChanged(event) {
    this.availabilitySeasonalOpeningElementTarget.replaceChildren(
      ...this.allSeasonalOpeningOptions.cloneNode(true).children
    )
    this.filterSeasonalOpeningOptions(event.target.value)
  }

  // Switch between different offer availabilities
  toggleAvailabilities(event) {
    const input = event.target.form.querySelector('input[value="'+event.target.htmlFor+'"]')
    if (input == null) return

    this.availabilityDaysTarget.classList.add("d-none")
    this.availabilitySeasonsTarget.classList.add("d-none")
    this.availabilityWrapperTarget.classList.add("d-none")
    if (input.value == "days") {
      this.availabilityDaysTarget.classList.remove("d-none")
    }
    else if (input.value == "seasons") {
      this.availabilitySeasonsTarget.classList.remove("d-none")
      window.dispatchEvent(new Event("resize"))
    }
    else if (input.value == "always") {
      this.availabilityWrapperTarget.classList.remove("d-none")
    }
  }

  // Hide always from/until fields according to availability switch value.
  toggleAvailabilitySeasonalOpenings(event) {
    if (this.availabilitySelectorTarget.value != "always") {
      this.availabilitySeasonalOpeningTarget.classList.remove("d-none")
    } else {
      this.availabilitySeasonalOpeningTarget.classList.add("d-none")
    }
  }

  // Switch between fixed price and price per person
  toggleFixedPrice(event) {
    if (this.offerTypeTarget.value == "single") {
      this.fixedPriceTarget.classList.toggle("d-none")
      this.fullPriceTarget.classList.toggle("d-none")
      this.reducedPriceTarget.classList.toggle("d-none")
      this.minParticipantsTarget.classList.toggle("d-none")
    }
    else if (this.offerTypeTarget.value == "group") {
      this.fixedGroupPriceTarget.classList.toggle("d-none")
      this.reducedFixedGroupPriceTarget.classList.toggle("d-none")
      this.minParticipantsTarget.classList.toggle("d-none")
      this.fullPriceTarget.classList.toggle("d-none")
      this.reducedPriceTarget.classList.toggle("d-none")
    }
  }

  // Switch between needing a guide and not needing a guide
  toggleNeedsGuide(event) {
    this.guideSkillsTarget.classList.toggle("d-none")
  }

  // If category is switched to 'movie', only allow one activity
  categoryChanged() {

    // Get selected offer category
    const category = document.querySelector(".offer-category").value

    // Remove all but first activity
    if (category == "movie") {
      const activities = document.querySelector(".activities")
      activities.innerHTML = activities.querySelectorAll(".element")[0].outerHTML
    }

    // Hide add-activity button, Hide remove-activity button
    document.querySelector(".add-activity").classList.toggle(
      "d-none", category == "movie"
    )
    document.querySelector(".remove-activity").classList.toggle(
      "d-none", category == "movie"
    )

    // Set room-type to movie-room and set to readonly
    if (category == "movie") {
      document.querySelector(".type-select").value = "movie"
    }
    document.querySelectorAll(".type-select option").forEach((option) =>
      option.toggleAttribute(
        "disabled", option.value != "movie" && category == "movie"
      )
    )
  }
}
